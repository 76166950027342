/* eslint-disable @typescript-eslint/unbound-method */
import { createApp, defineComponent } from 'vue';
import { scroll } from '@rescover/ui-library';;
import PrimeVue from 'primevue/config';
import Aura from '@primeuix/themes/aura';
import AutoComplete, { type AutoCompleteCompleteEvent, type AutoCompleteOptionSelectEvent } from 'primevue/autocomplete';
import AOS from 'aos';
import * as urlSlug from 'url-slug';
import * as utils from '~/utilities';
import * as formatters from '~/formatters-ts';
import { Manifest } from '~/Manifest';
import '~/extensions';
import { CountUp, type CountUpOptions } from 'countup.js';
import { ContentLoader } from 'vue-content-loader';
import { SentryPlugin } from '@rescover/ui-library';
import { rescoverApi, Exception, type ListingTileModel, RequestStatus, MelissaData, HomesPropertyTile, useAuthStore } from '@rescover/ui-library';
import { createPinia, mapStores } from 'pinia';

type vData = {
   selectedAddress?: MelissaData.AddressResult
   filteredAddresses?: Array<MelissaData.AddressResult>
   county?: string
   countyUrl?: string
   city?: string
   state?: string
   zip?: string
   listings: Array<ListingTileModel>
   featured: Array<ListingTileModel>
   showTotals: boolean
   featuredLoading: boolean
   preforeclosure: Array<ListingTileModel>
   preforeclosureLoading: boolean
   melissaToken?: string,
};

const pinia = createPinia();

const appComponent = defineComponent({
   name: 'HomePage',
   directives: {
      scroll
   },
   components: {
      ContentLoader,
      AutoComplete,
      Property: HomesPropertyTile
   },
   props: {
      googleApi: {
         type: String,
         required: true,
      },
   },
   data(): vData {
      return {
         selectedAddress: undefined,
         filteredAddresses: undefined,
         county: undefined,
         countyUrl: undefined,
         zip: undefined,
         city: undefined,
         state: undefined,
         listings: [],
         showTotals: true,
         featured: [],
         featuredLoading: true,
         preforeclosure: [],
         preforeclosureLoading: true,
         melissaToken: undefined,
      };
   },
   computed: {
      autoCompletePlaceholder(): string {
         if (this.melissaToken)
            return 'Enter Address';
         return 'Loading...';
      },
      preForeclosureUrl(): string {
         if (this.state && this.countyUrl)
            return `/pre-foreclosures/area/${this.state.toLowerCase()}/${this.countyUrl}`;
         return '/pre-foreclosures/search';
      },

      ...mapStores(useAuthStore),
   },
   created() {
      if (DEBUG)
         console.log('created');
   },
   mounted() {
      if (DEBUG)
         console.log('mounted');

      void this.authStore.initialize()
         .finally(() => {
            void rescoverApi.api.property.lookupToken()
               .then((resp) => {
                  this.melissaToken = resp.data?.token;
               });

            void this.retrieveTotals();
            void this.retrieveLocation()
               .then(this.preforeclosureListings)
               .then(this.retrieveListings);
            void this.featuredListings();
         });
   },
   methods: {
      currency: formatters.currency,
      percent: formatters.percent,
      number: formatters.number,

      urlSlug(string: string) {
         return urlSlug.convert(string, { camelCase: false });
      },

      //#region Search Box
      async searchAddress(event: AutoCompleteCompleteEvent) {
         let query = event.query;
         const mch = query?.match(/^(\d+)-(\d+)/i);
         if (mch?.length > 0) {
            query = query.replace(mch[0], mch[1]);
         }
         let resp = await MelissaData.melissaDataApi.api.expressEntry.expressFreeForm({
            format: 'json',
            id: this.melissaToken,
            ff: query,
            maxrecords: 30
         });
         if (resp.data.Results.length == 0 && mch) {
            // If no results, try second street number
            query = query.replace(mch[0], mch[2]);
            resp = await MelissaData.melissaDataApi.api.expressEntry.expressFreeForm({
               format: 'json',
               id: this.melissaToken,
               ff: query,
               maxrecords: 30
            });
         }
         this.filteredAddresses = resp.data.Results;
      },
      searchSelect(event: AutoCompleteOptionSelectEvent) {
         if (DEBUG)
            console.log('searchSelect', event.value);

         if (MelissaData.isMelissaObjectResponse(event.value) && !event.value.fullAddress)
            this.selectedAddress.fullAddress = this.buildAddress(this.selectedAddress);

         let zip = this.selectedAddress.Address.PostalCode;
         const mch = zip.match(/^(\d+)(-\d+)?/i);
         if (mch?.length > 0)
            zip = mch[1];

         window.location.href = utils.Str.format(Manifest.URL.Property.ByAddressZip, this.urlSlug(this.selectedAddress.Address.State), this.urlSlug(this.selectedAddress.Address.City), zip, this.urlSlug(this.selectedAddress.Address.AddressLine1));
      },
      buildAddress(suggestion: MelissaData.AddressResult) {
         if (DEBUG)
            console.log('buildAddress', suggestion);
         let whiteSpace = '';
         let entryCount = '';
         if (suggestion.Address.SuiteName) {
            if (suggestion.Address.SuiteCount > 1) {
               entryCount = ' (' + suggestion.Address.SuiteCount + ' entries)';
            }
            whiteSpace = ' ';
         }
         return suggestion.Address.AddressLine1 + whiteSpace + suggestion.Address.SuiteName + entryCount + ', ' + suggestion.Address.City + ', ' + suggestion.Address.State + ' ' + suggestion.Address.PostalCode;
      },
      //#endregion
      //#region Location
      async retrieveLocation() {
         try {
            const geo = await utils.findMyLocation();

            const locale = 'en';
            if (geo.cities && geo.cities[locale])
               this.city = geo.cities[locale];
            else
               console.warn('No city for location.');
            this.state = geo.stateIso;
            if (geo.postalCode)
               this.zip = geo.postalCode;
            else
               console.warn('No postal code for location.');
            if (geo.county) {
               this.county = geo.county;
               this.countyUrl = geo.countyUrl;
            }
            else
               console.warn('No county for location.');
         }
         catch {
            //const a = new utils.Alert('Local listing Error', (error as Error).message);
            //utils.showAlert(a);
         }
      },
      //#endregion
      //#region Local Listings
      async retrieveListings() {
         try {
            if (this.zip) {
               const resp = await rescoverApi.api.mls.localSearch(this.zip, false, 4);
               if (resp.data.status != RequestStatus.Success)
                  throw new Exception(resp);

               this.listings.length = 0;
               this.listings.push(...resp.data.results);
            }
         }
         catch {
            //const a = new utils.Alert('Local listing Error', (error as Error).message);
            //utils.showAlert(a);
         }
      },
      //#endregion
      buildUrl(listing: ListingTileModel) {
         return utils.Str.format(Manifest.URL.Property.ById, listing.propId, null);
      },
      viewProperty(property: ListingTileModel) {
         if (DEBUG)
            console.log(`${this.$options.name}.viewProperty`, property);

         if (property.queryString)
            window.open(property.url + property.queryString, `_${property.id}`);
         else
            window.open(property.url, `_${property.id}`);
      },
      handleScroll() {
         if (this.$refs.navbar) {
            if (document.body.scrollTop > 60 || document.documentElement.scrollTop > 60) {
               (this.$refs.navbar as HTMLElement).classList.add('sticky');
            } else {
               (this.$refs.navbar as HTMLElement).classList.remove('sticky');
            }
         }
      },
      //#region Totals
      formatNumber(n: number): string {
         if (n > 1_000_000)
            return this.number(Math.trunc(n / 1_000_000)) + 'M+';
         else if (n > 1_000)
            return this.number(Math.trunc(n / 1_000)) + 'K+';
         return this.number(n);
      },
      async retrieveTotals() {
         try {
            const resp = await rescoverApi.api.homes.stats();
            if (resp.data.status != RequestStatus.Success)
               throw new Error(resp.data.message);

            //#region Total Properties
            const optTotalProperties: CountUpOptions = {
               duration: 5,
               formattingFn: this.formatNumber,
               enableScrollSpy: true
            };
            const totalProperties = new CountUp(this.$refs.totalProperties as HTMLElement, resp.data.totalProperties, optTotalProperties);
            if (totalProperties.error && DEBUG) {
               console.error(totalProperties.error);
            }
            //#endregion
            //#region Total Listings
            const optTotalListings: CountUpOptions = {
               duration: 5,
               formattingFn: this.formatNumber,
               enableScrollSpy: true
            };
            const totalListings = new CountUp(this.$refs.totalListings as HTMLElement, resp.data.totalListings, optTotalListings);
            if (totalListings.error && DEBUG) {
               console.error(totalListings.error);
            }
            //#endregion
            //#region Total PreForeclosures
            const optTotalPreForeclosure: CountUpOptions = {
               duration: 5,
               formattingFn: this.formatNumber,
               enableScrollSpy: true
            };
            const totalPreForeclosure = new CountUp(this.$refs.totalPreForeclosures as HTMLElement, resp.data.totalPreForeclosure, optTotalPreForeclosure);
            if (totalPreForeclosure.error && DEBUG) {
               console.error(totalPreForeclosure.error);
            }
            //#endregion
            //#region Total Property Sale Records
            const optTotalPropertySaleRecords: CountUpOptions = {
               duration: 5,
               formattingFn: this.formatNumber,
               enableScrollSpy: true
            };
            const totalPropertySaleRecords = new CountUp(this.$refs.totalPropertySaleRecords as HTMLElement, resp.data.totalPropertySaleRecords, optTotalPropertySaleRecords);
            if (totalPropertySaleRecords.error && DEBUG) {
               console.error(totalPropertySaleRecords.error);
            }
            //#endregion
            //#region Total Mortgage Records
            const optTotalMortgageRecords: CountUpOptions = {
               duration: 5,
               formattingFn: this.formatNumber,
               enableScrollSpy: true
            };
            const totalMortgageRecords = new CountUp(this.$refs.totalMortgageRecords as HTMLElement, resp.data.totalMortgageRecords, optTotalMortgageRecords);
            if (totalMortgageRecords.error && DEBUG) {
               console.error(totalMortgageRecords.error);
            }
            //#endregion
         }
         catch {
            this.showTotals = false;
         }
      },
      //#endregion
      //#region Featured Listings
      async featuredListings() {
         try {
            this.featuredLoading = true;
            const resp = await rescoverApi.api.mls.featured(4);
            if (resp.data.status != RequestStatus.Success)
               throw new Exception(resp);

            this.featured.length = 0;
            this.featured.push(...resp.data.results);
         }
         catch {
            //const a = new utils.Alert('Local listing Error', (error as Error).message);
            //utils.showAlert(a);
         }
         this.featuredLoading = false;
      },
      //#endregion
      //#region Pre-Foreclosure Listings
      async preforeclosureListings() {
         try {
            this.preforeclosureLoading = true;
            if (this.state && this.countyUrl) {
               const resp = await rescoverApi.api.preForeclosure.areaByCounty(this.state, this.countyUrl, 4);
               if (resp.data.status != RequestStatus.Success)
                  throw new Exception(resp);

               this.preforeclosure.length = 0;
               this.preforeclosure.push(...resp.data.results);
            }
            else if (this.zip) {
               const resp = await rescoverApi.api.preForeclosure.areaByZip(this.zip, 4);
               if (resp.data.status != RequestStatus.Success)
                  throw new Exception(resp);

               this.preforeclosure.length = 0;
               this.preforeclosure.push(...resp.data.results);
            }
         }
         catch {
            //const a = new utils.Alert('Local listing Error', (error as Error).message);
            //utils.showAlert(a);
         }
         this.preforeclosureLoading = false;
      },
      //#endregion
      streetView(prop: ListingTileModel): string {
         return `https://maps.googleapis.com/maps/api/streetview?return_error_code=true&size=400x248&location=${encodeURIComponent(prop.address)}%20${encodeURIComponent(prop.city)},${encodeURIComponent(prop.state)}%20${encodeURIComponent(prop.postalCode)}&fov=80&key=${this.googleApi}`;
      },
      photoStyle(prop: ListingTileModel): object {
         return {
            'background-image': prop.photo ? `url(${prop.photo.replace(/\(/gi, '%28').replace(/\)/gi, '%29')})` : `url(${this.streetView(prop)})`
         };
      },
   },
});

createApp(appComponent)
   .use(SentryPlugin, {
      enabled: import.meta.env.PROD,
      release: import.meta.env.VITE_SENTRY_RELEASE,
   })
   .use(pinia)
   // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
   .use(AOS.init())
   .use(PrimeVue, {
      theme: {
         preset: Aura,
         options: {
            prefix: 'p',
            darkModeSelector: '.app-dark',
            cssLayer: false
         }
      }
   })
   .mount('#app');
